jQuery(document).ready(function($) {
  require('./components/mobile-header.js')
  require('./components/accordion.js')
  require('./components/video.js')
  require('./components/slider.js')


  jQuery(window).on('scroll', function() {
    if (1 < window.pageYOffset) {
      jQuery('header').addClass('scroll-active');
    } else {
      jQuery('header').removeClass('scroll-active');
    }
  });


  jQuery('.team .popup-button').click(function() {
    jQuery(this).closest('.team-member').addClass('active');
    jQuery('body, html').css('overflow-y', 'hidden');

    jQuery('.popup-close').click(function() {
      jQuery(this).closest('.team-member').removeClass('active');
      jQuery('body, html').css('overflow-y', 'auto');
    })
  });


  // Animated Section
  var animatedSections = jQuery('.animated-section');
  var windowHeight = window.innerHeight;

  if(animatedSections.length > 0) {
    animatedSections.each(function () {
      var section = jQuery(this);
      var offsetTop = section.offset().top;

      if(window.pageYOffset >= offsetTop - windowHeight / 1.5) {
        jQuery(section).addClass('active');
      }
    });

    window.addEventListener('scroll', function () {
      animatedSections.each(function () {
        var section = jQuery(this);
        var offsetTop = section.offset().top;

        console.log(offsetTop)
        console.log(window.pageYOffset)

        if(window.pageYOffset >= offsetTop - windowHeight / 1.5) {
          jQuery(section).addClass('active');
        }
      });
    });
  }
});


import AOS from 'aos';

AOS.init();

// You can also pass an optional settings object
// below listed default settings
AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'aos-init', // class applied after initialization
  animatedClassName: 'aos-animate', // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
  

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 20, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 400, // values from 0 to 3000, with step 50ms
  easing: 'ease', // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
});


(function($){
  'use strict';

  $.fn.enllax = function(opt){

    var elem = $('[data-enllax-ratio]');
    elem.each(function(){
      var $this = $(this);
      $this.css({
        'position': 'absolute',
        'left': '0',
        'right': '0'
      });
    });

    var winHeight = $(window).height();

    var options = $.extend({
      ratio: 0,
      type: 'background', //foreground
      direction: 'vertical', //horizontal
      offset: 0
    }, opt);

    elem.each(function(){
      var ratio;
      var type;
      var dir;
      var offset;
      var $this = $(this);
      var docHeight = $(document).height();
      var offsettop = $this.offset().top;
      var height = $this.outerHeight();
      var dataRat = $this.data('enllax-ratio');
      var dataType = $this.data('enllax-type');
      var dataDir = $this.data('enllax-direction');
      var dataOffset = $this.data('enllax-offset');

        if(dataRat) {
          ratio = dataRat;
        }
        else { ratio = options.ratio; }

        if(dataType) {
          type = dataType;
        }
        else { type = options.type; }

        if(dataDir) {
          dir = dataDir;
        }
        else { dir = options.direction; }

        if(dataOffset) {
          offset = dataOffset;
        }
        else { offset = options.offset; }

        var bgY = Math.round(offsettop * ratio);
        var transform = Math.round( ( offsettop - ( winHeight / 2 ) ) * ratio - offset );

        if(type == 'background') {
          if(dir == 'vertical') {
            $this.css({
              'background-position': 'center ' + -bgY + 'px'
            });
          }
          else if(dir == 'horizontal') {
            $this.css({
              'background-position': -bgY + 'px' + ' center'
            });
          }
        }
        else if(type == 'foreground') {
          if(dir == 'vertical') {
            $this.css({
              '-webkit-transform': 'translateY(' + transform + 'px)',
              '-moz-transform': 'translateY(' + transform + 'px)',
              'transform': 'translateY(' + transform + 'px)',
              'position': 'absolute',
              'left': '0',
              'right': '0'
            });
          }
          else if(dir == 'horizontal') {
            $this.css({
              '-webkit-transform': 'translateX(' + transform + 'px)',
              '-moz-transform': 'translateX(' + transform + 'px)',
              'transform': 'translateX(' + transform + 'px)'
            });
          }
        }

        $(window).on('scroll', function(){
          var docHeight = $(document).height();
          var offsettop = $this.offset().top;
          var height = $this.outerHeight();
          var scrolling = $(this).scrollTop();

          bgY = Math.round((offsettop - scrolling) * ratio);
          transform = Math.round( ( ( offsettop - ( winHeight / 2 ) ) - scrolling ) * ratio - offset );

          if(type == 'background') {
            if(dir == 'vertical') {
              $this.css({
                'background-position': 'center ' + -bgY + 'px'
              });
            }
            else if(dir == 'horizontal') {
              $this.css({
                'background-position': -bgY + 'px' + ' center'
              });
            }
          }
          else if((type == 'foreground') && (scrolling < docHeight)) {
            if(dir == 'vertical') {
              $this.css({
                '-webkit-transform': 'translateY(' + transform + 'px)',
                '-moz-transform': 'translateY(' + transform + 'px)',
                'transform': 'translateY(' + transform + 'px)',
                'position': 'absolute',
                'left': '0',
                'right': '0'
              });
            }
            else if(dir == 'horizontal') {
              $this.css({
                '-webkit-transform': 'translateX(' + transform + 'px)',
                '-moz-transform': 'translateX(' + transform + 'px)',
                'transform': 'translateX(' + transform + 'px)'
              });
            }
          }
        });
    });
  };

})(jQuery);